<template>
  <div class="contact">
    <div class="container my-4">
      <h1 class="h1 my-4" >Vous nous cherchez ?</h1>
    </div>
    <div class="container shadow rounded py-3 text-justify">
      <div class="row mt-5">
        <div class="col-sm-4" style="text-align: right;">
          <h2 class="h2 my-4 sticky-bottom">Une question? <br>
          Une réservation? <br>
          <br>Contactez-nous !</h2>
        </div>
        <div class="col-sm-8 self-align-justify">
           <ContactBox />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-4" style="text-align: right;">
          <h2 class="h2">Des gîtes idéalement situés près de Tournai.</h2>
          <p class="lead">
            509, Chaussée de Lille<br>
            7501 Orcq<br>
            Belgique.
          </p>
        </div>
        <div class="col-sm-8">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10129.092222646705!2d3.3437914!3d50.6034635!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7a3b210f821b2dd9!2sLE%20RELAIS%20DE%20LEONIE!5e0!3m2!1sfr!2sbe!4v1606385710374!5m2!1sfr!2sbe" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" class="rounded-xl"></iframe>
        </div>
      </div>
    </div>
    <div class="container center my-3">
      <router-link to="/gites" class="btn btn-success m-3">Réserver un gite</router-link>
    </div>
  </div>
</template>

<script>
import ContactBox from '@/components/ContactBox.vue'

export default {
  name: 'Contact',
  components: {
    ContactBox
  }
}
</script>
